import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";

import './Version.scss';
import { useState } from 'react';

export const Version = (props) => {
  const { protocolId, protocolNumber, branches, isEditAllowed } = props;
  const [showHistory, setShowHistory] = useState(Array(branches?.length || 0).fill(false));
  return (
    <>
      <br />
      <h2 className="sub-header" id="admin-title">{protocolNumber} - Versions</h2>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
          <tr key="title">
            <th>Version Name</th>
            <th>Last Updated</th>
            <th>Hash</th>
            <th>JSON</th>
            <th>Documents</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          {branches.map((branch, idx) => {
            return (
              <>
              <tr key={`${branch.name}`}>
                <td>{branch.name}</td>
                <td
                  id={`branch-last-update-${idx}`}
                  data-date={branch.date_updated_raw}
                >
                  {branch.date_updated}
                </td>
                <td id={`branch-hash-${idx}`} data-hash={branch.hash}>
                  {branch.hash ? branch.hash : '-'}
                  {branch.original_hash && (
                    <>
                      <br />
                      <span className="help-block">
                        Original: {branch.original_hash}
                      </span>
                    </>
                  )}
                </td>
                <td>
                  <button
                    name="json-view"
                    className='button-looks-like-link'
                    data-branch={branch.name}
                    onClick={() => {
                      window.protocol_branch_json_get(
                        protocolId,
                        protocolNumber,
                        branch.name,
                        isEditAllowed
                      );
                      return false;
                    }}
                  >View
                  </button>
                </td>
                <td>
                  <button
                    name="documents-view"
                    className='button-looks-like-link'
                    data-branch={branch.name}
                    onClick={() => {
                      window.protocol_branch_document_show(
                        protocolId,
                        protocolNumber,
                        branch.name,
                        isEditAllowed
                      );
                      return false;
                    }}
                  >View
                  </button>
                </td>
                <td>
                  <p id="branch-status" data-branch={branch.name}>
                    {branch.inactive ? 'Inactive': 'Active'}
                  </p>
                </td>
                <td>
                  <button
                  id={`button-history-${idx}`}
                  className='button-looks-like-link'
                  onClick={() => {
                    const showHistoryCopy = [...showHistory];
                    showHistoryCopy[idx] = !showHistoryCopy[idx];
                    setShowHistory(showHistoryCopy);
                  }}
                  >View {showHistory[idx] ? <AiFillCaretUp /> : <AiFillCaretDown />}
                  </button>
                </td>
              </tr>
              {
                showHistory[idx] && branch.version_migration_history?.map((history, historyIdx) => (
                  <tr id={`history-row-${idx}-${historyIdx}`}>
                    <td> - </td>
                    <td>{history.date_updated}</td>
                    <td>{history.previous_hash}</td>
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                 </tr>
                ))
              }
              </>
            )
          })}
          </tbody>
        </table>
      </div>
    </>
  );
};
