const ENTITY_TYPE_VISIT = 'visit';
const ENTITY_TYPE_SUBJECT_LOG = 'subjectLog';

/**
 * Gets the appropriate protocol instance based on the entity type and site protocol branch object.
 *
 * @param {Object} entity - The entity object.
 * @param {string} entityType - The type of the entity ('visit' or 'subjectLog').
 * @param {Object} siteProtocolBranchObject - The site protocol branch object.
 * @returns {string|null} - The appropriate protocol instance or null.
 */
export const getAppropriateProtocolInstance = (entity, entityType, siteProtocolBranchObject) => {
  if (!entity || !entityType || !siteProtocolBranchObject) {
    return null;
  }

  if (entityType === ENTITY_TYPE_VISIT) {
    // For a visit we receive a "visit info" object
    entity = entity.data.visit;
  }

  // Use the frozen protocol instance if the entity is frozen
  if ('protocol_instance_freeze' in entity) {
    return entity.protocol_instance_freeze.frozen_protocol_instance;
  }

  const entityDateCreated = entity.date_created;
  const entityProtocolInstance = entity.protocol_instance;

  if (!entityDateCreated || !entityProtocolInstance) {
    return null;
  }
  
  const { protocol_instance, date_updated } = siteProtocolBranchObject;
  if (protocol_instance) {
    if (date_updated > entityDateCreated) {
      // Case: site went through version migration whether skipped or not
      // and existing entity must use the protocol instance of the site whether skipped or not
      return protocol_instance;
    } else if (date_updated <= entityDateCreated) {
      // Case: new entity is created post version migration in a site whether skipped or not
      return entityProtocolInstance;
    }
  }

  // Case: site has never gone through version migration
  // or retro sites who went through version migration prior to 2.17.0 release, so use the latest protocol instance
  // This null is being taken care of in function protocolDefinitionFromData() at ./lib/protocolDefinitionUtils.js
  return null;
};
